/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.12.4-v202503212255-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * Capabilities that the user has been granted, including where the capability was inherited from (if applicable)
 */
export interface CapabilityInheritanceOutputV1 {
    /**
     * Name of the Capability
     */
    "capability"?: CapabilityEnum;
    "inheritedFrom"?: models.IdentityPreviewV1;
}

export enum CapabilityEnum {
    Admins = 'Admins' as any,
    AnalyticsAdministrationCapability = 'AnalyticsAdministrationCapability' as any,
    AuditTrailCapability = 'AuditTrailCapability' as any,
    UserAdministrationCapability = 'UserAdministrationCapability' as any,
    DatasourceAdministrationCapability = 'DatasourceAdministrationCapability' as any,
    LogViewerCapability = 'LogViewerCapability' as any
}
